import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import ReactMarkdown from 'react-markdown'
import StoryShowcase from '../components/home/storyShowcase'
import SEO from '../components/seo'
import LocaleManager from '../components/LocaleManager'
import { FormattedMessage, injectIntl } from 'react-intl'
import FullImageHero from '../components/home/FullImageHero'

interface StoryProps {
  data: GatsbyTypes.StoryQuery
  pageContext: {
    locale: string
  }
}

const OurStory: React.FC<StoryProps> = ({ data, pageContext: { locale } }) => (
  <LocaleManager locale={locale}>
    <FormattedMessage id="story.title">
      {(translation) => (
        <SEO
          title={translation ? translation.toString() : 'Discover'}
          description={
            data.description ? data.description.translation : 'Discover'
          }
          lang={locale}
        />
      )}
    </FormattedMessage>
    <FullImageHero
      subtitle={data.brandingOne?.text?.text}
      gatsbyImage={data.storyImage?.childImageSharp?.fluid}
      height="variable"
    />
    <div className="content">
      {data.journals.edges.map((journal) => (
        <>
          <FormattedMessage id="story.read">
            {(translation) => (
              <>
                {journal?.node?.image?.fluid && (
                  <StoryShowcase
                    key={journal.node.slug}
                    title={journal.node.title ?? 'Journal'}
                    text={journal.node.shortDescription ?? 'Description'}
                    image={journal.node.image.fluid}
                    date={journal.node.publishedDate}
                    cta={{
                      text: translation?.toString() ?? 'Read',
                      link: `${locale !== 'sv' ? '/' + locale : ''}/journal/${
                        journal.node.slug
                      }`,
                    }}
                  />
                )}
              </>
            )}
          </FormattedMessage>
        </>
      ))}
    </div>
  </LocaleManager>
)

export const query = graphql`
  query Story($locale: String) {
    journals: allContentfulJournal(
      sort: { fields: publishedDate, order: DESC }
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          slug
          title
          shortDescription
          publishedDate(formatString: "DD MMMM, YYYY", locale: $locale)
          image {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    description: contentfulTranslation(
      key: { eq: "story-description" }
      node_locale: { eq: $locale }
    ) {
      translation
    }
    storyImage: file(relativePath: { eq: "jamtland-50.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    brandingOne: contentfulBranding(
      title: { eq: "StoryPage-1" }
      node_locale: { eq: $locale }
    ) {
      text {
        text
      }
    }
    brandingTwo: contentfulBranding(
      title: { eq: "StoryPage-2" }
      node_locale: { eq: $locale }
    ) {
      text {
        text
      }
    }
  }
`;

export default OurStory
